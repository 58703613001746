export const parentVarians = {
  visible: {
    transition: {
      staggerChildren: 0.1,
    },
  },

  exit: {
    opacity: 0,
    y: -20,
  },
};

export const childVariants = {
  hidden: {
    y: 20,
    opacity: 0,
  },

  visible: {
    y: 0,
    opacity: 1,
  },
};
