import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

import { calcHeight } from 'assets/styles/mixins';
import { Text } from 'components/Atoms/Text/Text';

export const ContentItem = styled(motion.div)`
  margin: 1.4rem 0;

  :last-child {
    margin-bottom: 0;
  }

  :first-child {
    margin-top: 0;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  max-height: 70vh;
  ${calcHeight(70)}
  width: 100%;
  max-width: ${({ theme }) => theme.layout.maxContainerWidth};
  margin: 0 auto;
  padding: 0 4vw 10vh 20vw;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  z-index: ${({ theme }) => theme.zIndex.level1};
  overflow-y: scroll;

  ${({ theme }) => theme.mq.tablet} {
    margin-top: 90px;
  }
`;

export const StyledText = styled(Text)<{ isListItem: boolean }>`
  letter-spacing: 0.5px;

  ${({ isListItem }) =>
    isListItem &&
    css`
      margin: 0.5rem 0;
      position: relative;
      padding-left: 2rem;

      :last-child {
        margin-bottom: 0;
      }

      ::after {
        content: '';
        position: absolute;
        top: calc(50% - 0.5rem);
        left: 0;
        width: 1rem;
        height: 1rem;
        border: 1px solid ${({ theme }) => theme.colors.textLight};
        border-radius: 50%;
      }
    `}
`;

export const Inner = styled(motion.div)`
  max-width: 47rem;
`;
