import React, { FC } from 'react';

import { HeroImage } from 'components/Molecules/HeroImage/HeroImage';
import { useContent } from 'providers/ContentProvider/ContentProvider';
import { SideNav } from 'components/Molecules/SideNav/SideNav';
import { getCurrentNavItem } from 'helpers';
import { useTranslations } from 'hooks/useTranslations';
import { ContentItem, Wrapper, StyledText, Inner } from './About.style';
import type { AboutContent } from 'types';
import { parentVarians, childVariants } from './variants';

export const About: FC = () => {
  const { aboutPageContent } = useContent();
  const { image, aboutContent } = aboutPageContent;
  const { menu } = useTranslations();
  const [aboutItem, contactItem] = getCurrentNavItem({ menu, elements: [1, 3] });

  return (
    <>
      <HeroImage image={image} />
      <Wrapper>
        <Inner variants={parentVarians}>
          {aboutContent.map(({ id, type, listContent, paragraphContent }: AboutContent) => (
            <ContentItem key={id} variants={childVariants}>
              <StyledText variant="normal" isUpper isListItem={type === 'DatoCmsListItem'}>
                {listContent ?? paragraphContent}
              </StyledText>
            </ContentItem>
          ))}
        </Inner>
      </Wrapper>
      <SideNav mainItem={aboutItem} subItem={contactItem} />
    </>
  );
};
